import React from 'react'
import nl2br from '@helpers/misc/nl2br'
import useWidth from '@hooks/useWidth'
import Container from '@packages/justo-parts/lib/components/Container'

import Image from '../shared/Image'

import styles from './styles.module.css'

export default function MultiImageWithMessage({image, title, subtitle, items}) {
  const viewportWidth = useWidth()
  const isSmall = viewportWidth <= 768
  const imagePadding = 20

  const slides = items.map((item, index) => (
    <div className={styles.item} key={index}>
      <div className="row">
        {index % 2 === 0 ? (
          <div className="col-xs-12 col-sm-6 col-md-5">
            {item.image && (
              <Image
                fileId={item.image._id}
                alt=""
                className={styles.image}
                width={isSmall ? 360 : 1600}
                style={{maxWidth: `calc(100% - ${imagePadding}px)`, padding: `${imagePadding}px`}}
              />
            )}
          </div>
        ) : null}

        <div className="col-xs-12 col-sm-6 col-md-7">
          <h3 className={styles.itemTitle}>{item.title}</h3>
          <div className={styles.text}>{nl2br(item.text)}</div>
        </div>
        {index % 2 === 1 ? (
          <div className="col-xs-12 col-sm-6 col-md-5">
            {item.image && (
              <Image
                fileId={item.image._id}
                alt=""
                className={styles.image}
                width={viewportWidth - imagePadding}
                style={{maxWidth: `calc(100% - ${imagePadding}px)`, padding: `${imagePadding}px`}}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  ))
  return (
    <Container size="medium">
      <div className={styles.container}>
        {title || subtitle ? (
          <div className={styles.header}>
            {title ? <h1 className={styles.title}>{title}</h1> : null}
            {subtitle ? <p>{subtitle}</p> : null}
          </div>
        ) : null}
        {slides}
      </div>
    </Container>
  )
}
